import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import DevelopmentImage from "../images/development_image.png";
import DeviceImage from "../images/devices.svg";
import ContactImage from "../images/connect_image.svg";

const IndexPage = () => (
  <Layout>
    <SEO
      title="Ruby on Rails and React Development Company"
      isBlogPost={false}
      keywords={
        [
          `Ruby on Rails`,
          `Ruby on Rails Development Company`,
          `Ruby on Rails Consulting Company`,
          `Ruby`,
          `Ruby Consulting Company`,
          `Ruby Development Company`,
          `React`,
          `React Consulting Company`,
          `React Development Company`
        ]
      }
    />
    <div className="container mx-auto">
      <div className="flex flex-wrap flex-col md:flex-row items-center p-6 md:mt-6">
        <div className="flex flex-col w-full md:w-3/6 justify-center lg:items-start overflow-y-hidden">

          <h1 className="text-4xl font-bold leading-tight text-center md:text-left">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-700 via-pink-700 to-teal-700">
              Hey, Welcome!
            </span>
          </h1>

          <p className="leading-normal text-base md:text-1xl mb-8 text-center md:text-left">
            We are a small team of passionate web application developers based out of India. We have several years of experience in product development across multiple domains.
            <br /><br />
            We take immense pride in delivering a master piece of work and hone our skills to deliver something best each time and on time.
            <br /><br />
            We respect our clients' vision and are absolutely determined to deliver the best version.
          </p>
        </div>
        <div className="flex justify-center w-full md:w-3/6 py-2 items-center">
          <img src={DevelopmentImage} alt="" />
        </div>
      </div>
      <div className="flex flex-wrap flex-col md:flex-row items-center">
        <div className="flex justify-center w-full md:w-3/6 items-center hidden sm:flex">
          <img src={DeviceImage} alt="" />
        </div>
        <div className="flex flex-col w-full md:w-3/6 justify-center overflow-y-hidden">
          <p className="leading-normal text-base text-center md:text-1xl mb-8">
            We enjoy working with Ruby on Rails and React.<br />
            And, these awesome libraries help us to build most of our applications.
          </p>
          <div className="flex flex-col md:flex-row">
            <span className="flex items-center justify-center px-10 py-5 border w-full text-center md:min-w-40">Ruby on Rails</span>
            <span className="flex items-center justify-center mt-5 md:mt-0 md:ml-5 px-10 py-5 border w-full text-center md:min-w-40">React</span>
          </div>
        </div>
      </div>

      <div className="flex w-full min-h-1/2 bg-site-blue-lighter mt-16 md:mt-24 pb-10 shadow-md">
        <div className="flex-col md:pl-10 md:mt-16 md:w-3/6 w-full pl-4 pr-2 md:pr-10">
          <h1 className="mt-4 mb-16 text-2xl md:text-4xl tracking-widest">
            Let's connect!
          </h1>
          <p className="leading-normal text-base md:text-1xl mb-8 text-left">
            Are you looking for an expert Ruby on Rails or React team ? <br />
            We would love to talk to you.
          </p>
          <a href="mailto:info@scriptday.com" className="px-10 py-4 bg-site-blue-dark text-white shadow-lg w-full">
            Let's Talk
          </a>
        </div>
        <div className="flex justify-center md:w-3/6 w-full">
          <img src={ContactImage} alt="" />
        </div>
      </div>

    </div>
  </Layout>
)

export default IndexPage;
